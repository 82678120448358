/*
 * @Autor: lianxiben
 * @Date: 2023-04-23 14:48:40
 * @LastEditors: lianxiben
 * @LastEditTime: 2023-04-27 17:29:57
 */
import BrowserLogger from "alife-logger";
export const getTimeStamp = () => {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  let day = today.getDate();
  day = day < 10 ? "0" + day : day;
  let hour = today.getHours();
  hour = hour < 10 ? "0" + hour : hour;
  const minute = today.getMinutes();
  const sec = today.getSeconds();
  // const timeStamp = year + "" + month + "" + day + "" + hour + "" + minute + "" + sec;
  const timeStamp = `${year}${month}${day}${hour}${minute}${sec}`;
  return timeStamp;
};

const NODE_ENV = process.env.NODE_ENV || "development";
/**
 * ARMS 埋点
 */
export const buriedLogger = (pageName) => {
  const pid = "egrrccpv71@232921d2c1e9c18";
  return BrowserLogger.singleton({
    pid,
    appType: "web",
    // disabled: NODE_ENV === 'development',
    environment: NODE_ENV === "development" ? "local" : "prod",
    imgUrl: "https://arms-retcode.aliyuncs.com/r.png?",
    sendResource: true,
    enableLinkTrace: true,
    behavior: true,
    page: pageName,
  });
};
