<!--
 * @Autor: lianxiben
 * @Date: 2023-11-20 14:14:13
 * @LastEditors: lianxiben
 * @LastEditTime: 2023-11-20 14:17:41
-->
<template>
  <div class="header-bgc">
    <div class="left-icon"></div>
    <div class="mid-info">
      <div class="username">{{ nickName || "时光旅行社" }}</div>
      <div>{{ account }}</div>
    </div>
    <div class="download-btn" @click="downLoadBtn()"></div>
    <div class="right-btn" v-if="loginType == 1" @click="showLoign()"></div>
    <div class="right-btn-sec" v-if="loginType == 2" @click="loginOut()"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loginType: 1, //1为未登录 2为登录
      account: "",
      nickName: "",
      url: {},
    };
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.loginType = 2;
      this.account = localStorage.getItem("account");
    }
    this.$EventBus.$on("loginSuccess", () => {
      this.loginType = 2;
      this.account = localStorage.getItem("account");
    });

    // 账号被注销操作
    this.$EventBus.$on("loginOutInit", () => {
      this.loginOut();
    });
    this.$api.downloadUrl().then((res) => {
      this.url = res.data.data;
    });
  },
  methods: {
    // 登录
    showLoign() {
      this.$EventBus.$emit("login");
    },
    // 注销
    loginOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("account");
      localStorage.removeItem("sdkToken");
      this.$EventBus.$emit("logout");
      this.loginType = 1;
      this.account = "";
    },
    // 下载
    downLoadBtn() {
      // ua判断苹果或者安卓
      const ua = window.navigator.userAgent.toLowerCase();
      if (ua.indexOf("iphone") > -1) {
        //  判断是否为微信
        if (ua.indexOf("micromessenger") > -1) {
          window.location.href = "https://sglxs.qingcitimes.com/";
        } else window.location.href = this.url.ios_down_url;
        return;
      }
      window.location.href = this.url.android_down_url;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./HeaderContainer.scss";
</style>
