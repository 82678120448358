<!--
 * @Autor: lianxiben
 * @Date: 2023-11-17 23:18:40
 * @LastEditors: lianxiben
 * @LastEditTime: 2023-11-21 18:06:24
-->
<template>
  <div class="fir-container">
    <div class="title"></div>
    <div class="bot-container">
      <div class="appointment-btn" @click="scrollClick()"></div>
      <div class="num">
        <div class="num-body">{{ bookNum }}</div>
      </div>
      <div class="num-title">当前预约人数</div>
      <div class="social-btn-list">
        <div class="taptap" @click="goTaptap()"></div>
        <div class="wechat" @click="goWiki()"></div>
        <div class="hb" @click="hb()"></div>
        <!-- <div class="qq" @click="openGroup()"></div> -->
        <!-- <div class="group"></div> -->
        <div class="ohter" @click="goOhter()"></div>
      </div>
    </div>
    <van-popup v-model="showWiki">
      <div class="wiki-share">
        <img src="./../../assets/image/popup/conversion_share.png" alt="" />
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bookNum: 0, //预约人数
      showWiki: false, //企业微信弹窗
    };
  },
  mounted() {
    this.$EventBus.$on("getBookNum", (e) => {
      this.bookNum = this.numFormat(e);
    });
  },
  methods: {
    numFormat(num) {
      num = num.toString().split(".");
      let arr = num[0].split("").reverse();
      var res = [];
      for (let i = 0, len = arr.length; i < len; i++) {
        if (i % 3 === 0 && i !== 0) {
          res.push(",");
        }
        res.push(arr[i]);
      }
      res.reverse();
      if (num[1]) {
        res = res.join("").concat("." + num[1]);
      } else {
        res = res.join("");
      }
      return res;
    },
    // 预约按钮
    scrollClick() {
      if (!localStorage.getItem("token")) {
        return this.$EventBus.$emit("login");
      }
      this.$EventBus.$emit("scrollClick");
    },
    // 跳转taptap
    goTaptap() {
      window.location.href = "https://www.taptap.cn/app/387611";
    },
    // 企业微信弹窗
    goWiki() {
      this.showWiki = true;
    },
    // qq群
    openGroup() {
      this.$EventBus.$emit("showGroup");
    },
    // 好爆游戏
    hb() {
      window.location.href = "https://www.3839.com/a/119332.htm";
    },
    goOhter() {
      window.location.href = "https://www.gamer.cn/sglxs";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./FirstPage.scss";
</style>
