/*
 * @Autor: lianxiben
 * @Date: 2023-04-12 16:33:58
 * @LastEditors: lianxiben
 * @LastEditTime: 2023-04-23 16:04:10
 */
import Vue from "vue";
import Vuex from "vuex";
import wx from "weixin-js-sdk";
import api from "./../api/art.js";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // 设置分享信息
    setShare({ state, commit }, config) {
      const { uri = window.location.href } = config;
      api.settingShare({ uri }).then((response) => {
        const data = response.data.data;
        data.jsApiList = [
          "chooseWXPay",
          "updateAppMessageShareData",
          "updateTimelineShareData",
        ];
        const ua = navigator.userAgent.toLowerCase();
        const { link = window.location.href, title, desc, imgUrl } = config;
        const isWeChatBrowser =
          ua.match(/Micromessenger/i) &&
          ua.match(/Micromessenger/i)[0] === "micromessenger";
        if (isWeChatBrowser === true) {
          wx.config(data);
          wx.ready(function () {
            wx.updateAppMessageShareData({ link, title, desc, imgUrl });
            wx.updateTimelineShareData({ link, title, imgUrl });
          });
        } else {
          // qq 分享设置
          setShareInfo({
            title: title, // 分享标题
            summary: desc, // 分享内容
            pic: imgUrl, // 分享图片
            url: link, // 分享链接
            // 微信权限验证配置信息，若不在微信传播，可忽略
            WXconfig: {
              appId: data.appId, // 公众号的唯一标识
              timestamp: data.timestamp, // 生成签名的时间戳
              nonceStr: data.nonceStr, // 生成签名的随机串
              signature: data.signature, // 签名
            },
          });
        }
      });
    },
  },
  modules: {},
});
