<template>
  <div class="login-container">
    <van-popup v-model="showLogin">
      <div class="login-box">
        <div class="header-container">
          <div class="header-btn">
            <div class="close" @click="closeLoginContainer()"></div>
          </div>
        </div>
        <div class="login-tabs-container">
          <div class="tabs-list-container">
            <div class="input-container">
              <div class="input-body">
                <input
                  type="tel"
                  placeholder="请输入手机号"
                  v-model="phoneInput"
                />
                <div class="input-title">
                  <div class="phone"></div>
                </div>
              </div>
            </div>
            <div class="input-container">
              <div class="input-body">
                <input
                  type="tel"
                  placeholder="请输入验证码"
                  class="sec-input"
                  v-model="codeInput"
                  maxlength="6"
                />
                <div class="input-title">
                  <div class="sms"></div>
                </div>
                <button class="msg-btn" :disabled="disabled" @click="sendMsg()">
                  {{ send }}
                </button>
              </div>
            </div>
            <div class="login-btn">
              <button @click="loginSms()">登录</button>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup v-model="gameRolePopup" @close="closeRole()">
      <div class="pc-container">
        <div class="close-ico" @click="gameRolePopup = false">
          <img src="./../../assets/image/loginPopup/close.png" alt="" />
        </div>
        <div class="game-role-container">
          <div class="title">选择游戏区组</div>
          <div class="system-list">
            <div
              class="sys-btn"
              :class="[sysTem == '62' ? 'system-choose' : '']"
              @click="chooseSystem(62)"
            >
              <div>光子服</div>
            </div>
            <div
              class="sys-btn"
              :class="[sysTem == '35' ? 'system-choose' : '']"
              @click="chooseSystem(35)"
            >
              <div>正式服</div>
            </div>
          </div>
          <select
            class="aread-container"
            v-model="chooseArea"
            @change="choiceGameDistName($event)"
          >
            <option disabled>请选择区服</option>
            <option
              v-for="(item, index) in areaList"
              :key="item.role_id"
              :value="index"
            >
              {{ item.server_name }}
            </option>
          </select>
          <!-- <select class="aread-container" disabled>
                            <option>{{ roleName || '请选择角色' }}</option>
                        </select> -->
          <div class="aread-container">{{ roleName || "请选择角色" }}</div>
          <div class="btn-list">
            <div class="cancle" @click="gameRolePopup = false">取消</div>
            <div class="confirm" @click="confirmChoose()">确定</div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Vue from "vue";
import { Toast, Tab, Tabs, Popup, Dialog } from "vant";
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Popup);
export default {
  data() {
    return {
      sysTem: 62, //62光子服，35正式服
      gameRolePopup: false,
      showLogin: false,
      loginActive: 0, // tabs序号
      phoneInput: "",
      codeInput: "",
      userPw: "",
      inputUserId: "",
      send: "发送",
      disabled: false,
      timer: 60,
      pwType: "password",
      areaList: [], //服务器信息
      roleName: "",
      roleId: "",
      chooseArea: "请选择区服",
      allRoleList: {},
      loginType: "", //从何出调出登录弹窗
      invitedCode: "",
      infoList: {}, //选择的角色信息
      loginBtn: true, //
    };
  },
  mounted() {
    if (this.$route.query.code) {
      this.invitedCode = this.$route.query.code;
    }
    // 调出登录弹窗
    this.$EventBus.$on("login", () => {
      this.showLogin = true;
    });
    // 调出角色选择弹窗
    this.$EventBus.$on("gameRole", () => {
      this.showRolePopup();
    });
  },
  methods: {
    showRolePopup() {
      this.gameRolePopup = true;
      this.getGameId();
    },
    // 关闭弹窗
    closeLoginContainer() {
      this.showLogin = false;
      this.clearInput();
    },
    clearInput() {
      this.phoneInput = "";
      this.codeInput = "";
      this.userPw = "";
      this.inputUserId = "";
    },
    // 账号登录
    gameLogins() {
      if (!this.inputUserId || !this.userPw) {
        return Toast("账号信息填写错误");
      }
      this.clearInput();
    },
    // 发送短信验证码
    sendMsg() {
      if (!this.phoneInput) {
        return Toast("账号信息未填写");
      }
      this.disabled = true;
      let auth_timer = setInterval(() => {
        this.timer--;
        this.send = `${this.timer}秒`;
        if (this.timer <= 0) {
          this.disabled = false;
          this.send = "重新发送";
          this.timer = 60;
          clearInterval(auth_timer);
        }
      }, 1000);
      this.$api
        .sendMsg({
          mobile: this.phoneInput,
          channel: "login",
        })
        .then((res) => {
          if (res.code == 200) {
            Toast("发送成功");
          }
        });
    },
    // 短信验证码登录
    loginSms() {
      if (!this.loginBtn) return Toast("请勿频繁点击");
      if (!this.phoneInput || !this.codeInput) {
        return Toast("账号信息未填写");
      }
      // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      this.loginBtn = false;
      this.$api
        .loginByPhone({
          account: this.phoneInput,
          sms_code: this.codeInput,
          game_id: 35,
          type: 1,
        })
        .then((res) => {
          console.log(res);
          if (res && res.data.code == 200) {
            localStorage.setItem("token", res.data.data.access_token);
            localStorage.setItem("account", this.phoneInput);
            localStorage.setItem("sdkToken", res.data.data.sdK_access_token);
            this.phoneInput = "";
            this.codeInput = "";
            this.$EventBus.$emit("loginSuccess");
            this.$EventBus.$emit("refreshInit");
            this.showLogin = false;
            Toast("登录成功");
          } else {
            Toast(res.data.message);
          }
          setTimeout(() => {
            this.loginBtn = true;
          }, 3000);
        });
    },
    // 获取区服信息
    getGameId() {
      this.$api
        .getGameIdNew({
          account: localStorage.getItem("account"),
          page: 2,
          app_id: 35,
        })
        .then((res) => {
          console.log(res);
          if (res && res.data.code == 200) {
            this.allRoleList = res.data.data;
            if (res.data.data[this.sysTem]) {
              this.areaList = this.allRoleList[this.sysTem]["android"];
            } else {
              this.areaList = [];
              Toast("暂无注册角色");
            }
          } else {
            this.areaList = [];
            this.chooseArea = "请选择区服";
            this.roleName = "";
            Toast("暂无注册角色");
          }
        });
    },
    // 选取区服
    choiceGameDistName(e) {
      this.roleId = this.areaList[e.target.selectedIndex - 1].role_id;
      this.roleName = this.areaList[e.target.selectedIndex - 1].role_name;
      this.infoList = this.areaList[e.target.selectedIndex - 1];
      this.extra = JSON.stringify(
        this.areaList[e.target.selectedIndex - 1].extra
      );
    },
    // 选择系统.extra
    chooseSystem(e) {
      this.sysTem = e;
      this.chooseArea = "请选择区服";
      this.roleName = "";
      if (this.allRoleList[this.sysTem]) {
        this.areaList = this.allRoleList[this.sysTem]["android"];
      } else {
        this.areaList = [];
        Toast("暂无注册角色");
      }
    },
    // 确定角色选择
    confirmChoose() {
      if (!this.roleName) {
        return Toast("请完成区服选择后再绑定");
      }
      Dialog.confirm({
        title: "角色绑定确认",
        message: `请确认领取到${this.infoList.server_name}-${this.infoList.role_name}`,
        confirmButtonColor: "#8383ff",
      })
        .then(() => {
          this.$api
            .bingding({
              role_id: this.infoList.role_id,
              role_name: this.infoList.role_name,
              server_id: this.infoList.server_id,
              server_name: this.infoList.server_name,
              game_id: this.infoList.game_id,
              platform: this.infoList.platform,
              extra: this.extra,
              dist: 35,
              account: localStorage.getItem("account"),
            })
            .then(() => {
              this.gameRolePopup = false;
              this.$EventBus.$emit("refreshInit", true);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 关闭角色选取弹窗清空内容
    closeRole() {
      this.chooseArea = "请选择区服";
      this.roleName = "";
      this.areaList = [];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./LoginPage.scss";
</style>
>
