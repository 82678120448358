<template>
  <div class="four-page">
    <div class="title"></div>
    <div class="example-3d">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide>
          <div class="slide-page"></div>
        </swiper-slide>
        <swiper-slide>
          <div class="slide-page2"></div>
        </swiper-slide>
        <swiper-slide>
          <div class="slide-page3"></div>
        </swiper-slide>
        <swiper-slide>
          <div class="slide-page4"></div>
        </swiper-slide>
        <swiper-slide>
          <div class="slide-page5"></div>
        </swiper-slide>
        <swiper-slide>
          <div class="slide-page6"></div>
        </swiper-slide>
        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      </swiper>
    </div>
    <div class="pagination-container">
      <div class="swiper-pagination" slot="pagination"></div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
// const mySwiper = document.getElementsByClassName("my-swiper");
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        initialSlide: 1,
        loop: true,
        speed: 600,
        autoplay: true,
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
};
</script>
<style lang="scss">
@import "./FourPage.scss";
</style>
