<template>
  <div class="sec-container">
    <div class="title"></div>
    <div class="num">
      <div class="num-body">{{ bookNum }}</div>
    </div>
    <div class="num-title">当前预约人数</div>

    <div :class="`mid-container${progress}`"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bookNum: 0,
      progress: 1,
    };
  },
  mounted() {
    // 获取预约人数
    this.$EventBus.$on("getBookNum", (e) => {
      this.bookNum = this.numFormat(e);
      let type = e;
      if (type >= 200000 && type < 400000) {
        this.progress = 2;
      } else if (type >= 400000 && type < 600000) {
        this.progress = 3;
      } else if (type >= 600000 && type < 800000) {
        this.progress = 4;
      } else if (type >= 800000 && type < 1000000) {
        this.progress = 5;
      } else if (type >= 1000000) {
        this.progress = 6;
      }
    });
  },
  methods: {
    numFormat(num) {
      num = num.toString().split(".");
      let arr = num[0].split("").reverse();
      var res = [];
      for (let i = 0, len = arr.length; i < len; i++) {
        if (i % 3 === 0 && i !== 0) {
          res.push(",");
        }
        res.push(arr[i]);
      }
      res.reverse();
      if (num[1]) {
        res = res.join("").concat("." + num[1]);
      } else {
        res = res.join("");
      }
      return res;
    },
  },
};
</script>
268
<style lang="scss" scoped>
@import "./SecPage.scss";
</style>
