<template>
  <div class="three-container">
    <div id="aliPayBtn"></div>
    <div class="title"></div>
    <div class="update-container">
      <div class="fir">
        <div class="fir-num">{{ firType.income || 0 }}/次</div>
        <div class="btn" @click="upDate(1)"></div>
      </div>
      <div class="sec">
        <div class="sec-num">{{ secType.income || 0 }}/秒</div>
        <div class="btn" @click="upDate(2)"></div>
      </div>
      <div class="three">
        <div class="three-num">{{ threeType.income || 0 }}/次</div>
        <div class="btn" @click="upDate(3)"></div>
      </div>
    </div>
    <van-popup v-model="updataPopup">
      <div :class="`update-${buildId}`" v-if="buildId > 0">
        <div class="update-title">当前LV.{{ updateInfo.lv }}</div>
        <div class="update-detail" v-if="buildId == 1">
          亲和度收集量：{{ updateInfo.income }}/每次点击
        </div>
        <div class="update-detail" v-if="buildId == 2">
          亲和度收集量：{{ updateInfo.income }}/每秒
        </div>
        <div class="update-detail" v-if="buildId == 3">
          每日亲和度收集次数:{{ updateInfo.income }}次
        </div>

        <div class="update-title">升级后LV.{{ updateInfo.lv + 1 }}</div>
        <div class="update-detail" v-if="buildId == 1">
          亲和度收集量：{{ updateInfo.next_income }}/每次点击
        </div>
        <div class="update-detail" v-if="buildId == 2">
          亲和度收集量：{{ updateInfo.next_income }}/每秒
        </div>
        <div class="update-detail" v-if="buildId == 3">
          每日亲和度收集次数:{{ updateInfo.next_income }}次
        </div>
        <div class="update-bot">消耗{{ updateInfo.consume }}亲和度</div>
        <div class="update-btn" @click="requestUpDate()"></div>
      </div>
    </van-popup>
    <div class="mid-btn-list">
      <div class="reward-btn" @click="reward()"></div>
      <div class="qmd-btn" @click="collectAffinity()">
        <div class="detail-icon" @click.stop="detailShow()"></div>
        <div class="lovt-num" v-if="gameCourse.activity">
          今日剩余：{{ gameCourse.activity.times || 0 }}次
        </div>
      </div>
      <div class="share-btn" @click="share()">
        <div class="tips">+10次收集机会</div>
      </div>
    </div>

    <div class="affinity">
      <div class="line"></div>
      <div>亲和度: {{ affinity }}</div>
      <div class="refresh-icon" @click="refreshAffinity()"></div>
    </div>

    <div class="progress-container">
      <div class="progress">
        <div class="progress-num">
          <div class="num-container">200000</div>
          <div class="num-container">400000</div>
          <div class="num-container">600000</div>
          <div class="num-container">800000</div>
          <div class="num-container">1000000</div>
        </div>
        <div class="progress-reward-list"></div>
        <div class="progress-bar" :style="{ width: progress + '%' }">
          <div class="progress-star"></div>
        </div>
      </div>
    </div>

    <div class="task-title-bgc"></div>
    <div class="task-list-four" style="margin-bottom: 0">
      <div
        class="other-click"
        v-if="
          (gameCourse.activity &&
            gameCourse.activity.task_list[3].status != 1) ||
          !gameCourse.activity
        "
        @click="taskClick(3)"
      ></div>
      <div
        @click="taskClick(3)"
        class="task-success"
        v-if="
          gameCourse.activity && gameCourse.activity.task_list[3].status == 1
        "
      ></div>
    </div>
    <div class="task-list">
      <div
        class="taptap-click"
        v-if="
          (gameCourse.activity &&
            gameCourse.activity.task_list[1].status != 1) ||
          !gameCourse.activity
        "
        @click="taskClick(1)"
      ></div>
      <div
        class="task-success"
        @click="taskClick(1)"
        v-if="
          gameCourse.activity && gameCourse.activity.task_list[1].status == 1
        "
      ></div>
    </div>
    <div class="task-list-sec">
      <div
        class="other-click"
        v-if="
          (gameCourse.activity &&
            gameCourse.activity.task_list[2].status != 1) ||
          !gameCourse.activity
        "
        @click="taskClick(2)"
      ></div>
      <div
        @click="taskClick(2)"
        class="task-success"
        v-if="
          gameCourse.activity && gameCourse.activity.task_list[2].status == 1
        "
      ></div>
    </div>
    <!-- <div class="task-list-three" style="margin-bottom: 0" v-if="!isWechat">
      <div
        class="other-click"
        v-if="
          (gameCourse.activity &&
            gameCourse.activity.task_list[3].status != 1) ||
          !gameCourse.activity
        "
        @click="taskClick(3)"
      ></div>
      <div
        @click="taskClick(3)"
        class="task-success"
        v-if="
          gameCourse.activity && gameCourse.activity.task_list[3].status == 1
        "
      ></div>
    </div> -->
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      token: localStorage.getItem("token"),
      progress: 0, //进度条
      gameCourse: {}, //当前进程
      affinity: 0, //当前亲和度
      firType: {}, //顶部升级列表信息1
      secType: {}, //顶部升级列表信息2
      threeType: {}, //顶部升级列表信息3
      updataPopup: false, //亲和度升级弹窗
      buildId: 0, //升级的类型
      updateInfo: {}, //升级弹窗内容
      taskIng: false,
      isWechat: false,
    };
  },
  mounted() {
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.isWechat = true;
    }
    // 获取当前账号的进度内容
    this.$EventBus.$on("initSuccess", (e) => {
      this.token = localStorage.getItem("token");
      this.gameCourse = e;
      this.affinity = e.activity.affinity;
      // 进度条计算
      this.progressCompute();
      this.firType = e.activity.build_info[1];
      this.secType = e.activity.build_info[2];
      this.threeType = e.activity.build_info[3];
    });
    // 注销
    this.$EventBus.$on("logout", () => {
      this.token = "";
      this.gameCourse = "";
      this.affinity = 0;
      this.progress = 0;
      this.firType = {};
      this.secType = {};
      this.threeType = {};
    });
  },
  methods: {
    // 进度条计算
    progressCompute() {
      if (this.affinity < 200000) {
        this.progress = 0;
      } else if (this.affinity < 400000) {
        this.progress = 20;
      } else if (this.affinity < 600000) {
        this.progress = 40;
      } else if (this.affinity < 800000) {
        this.progress = 60;
      } else if (this.affinity < 1000000) {
        this.progress = 80;
      } else {
        this.progress = 100;
      }
    },
    //点击升级按钮
    upDate(e) {
      if (!this.token) {
        return this.$EventBus.$emit("login");
      }
      this.buildId = e;
      switch (e) {
        case 1:
          if (this.firType.lv < 3) {
            this.updateInfo = this.firType;
            this.updataPopup = true;
          } else Toast("已达到最高等级");
          break;

        case 2:
          if (this.secType.lv < 3) {
            this.updateInfo = this.secType;
            this.updataPopup = true;
          } else Toast("已达到最高等级");
          break;
        case 3:
          if (this.threeType.lv < 3) {
            this.updateInfo = this.threeType;
            this.updataPopup = true;
          } else Toast("已达到最高等级");
          break;
        default:
      }
    },
    // 请求升级接口
    requestUpDate() {
      if (
        this.gameCourse.activity.build_info[this.buildId].consume >
        this.affinity
      ) {
        return Toast("亲和度不足");
      }
      this.$api
        .upgrade({
          book_token: this.token,
          build_id: this.buildId,
          game_id: 35,
        })
        .then((res) => {
          if (res && res.data.code == 200) {
            this.updataPopup = false;
            Toast("升级成功");
            // 重新获取当前进度
            this.$EventBus.$emit("refreshInit");
          }
        });
    },
    // 收集亲密
    collectAffinity() {
      if (!this.token) {
        return this.$EventBus.$emit("login");
      }
      if (this.progress == 100) {
        return Toast("当前亲和度已经达到上限");
      }
      if (!localStorage.getItem("guidePopup")) {
        return this.$EventBus.$emit("showGuidePopup");
      }
      this.$api
        .collect({
          book_token: this.token,
          game_id: 35,
        })
        .then((res) => {
          if (res && res.data.code == 200) {
            Toast(`亲和度+${res.data.data.income}`);
            // 重新获取当前进度
            this.$EventBus.$emit("refreshInit");
          }
        });
    },
    // 世界任务内容
    taskClick(e) {
      if (!this.token) {
        return this.$EventBus.$emit("login");
      }
      if (this.taskIng) {
        return Toast("当前有任务正在进行");
      }
      if (this.gameCourse.activity.task_list[e].status == 1) {
        this.$EventBus.$emit("taskClickRecord", e);
        return;
      }
      this.taskIng = true;
      this.$api
        .finish({
          book_token: this.token,
          game_id: 35,
          task_id: e,
        })
        .then((res) => {
          if (res && res.data.code == 200) {
            // 点击记录
            this.$EventBus.$emit("taskClickRecord", e);
            setTimeout(() => {
              this.taskIng = false;
              this.$EventBus.$emit("refreshInit");
              Toast("任务完成");
            }, 5000);
          }
        });
    },
    // 领取奖励
    reward() {
      this.$EventBus.$emit("rewardClick");
      if (!this.token) {
        return this.$EventBus.$emit("login");
      }
      if (!this.gameCourse.activity.is_online) {
        return Toast("12月22日上线后可领取");
      }
      if (
        this.gameCourse.activity.reward_list.length == 5 &&
        this.gameCourse.activity.is_pay == 0
      ) {
        Toast("您已领取当前所有奖励");
        return;
      }
      if (
        this.gameCourse.activity.reward_list.length == 5 &&
        this.gameCourse.activity.is_get == 1
      ) {
        Toast("您已领取当前所有奖励");
        return;
      }
      if (!this.gameCourse.userinfo.role_id) {
        // evenbus绑定角色
        this.$EventBus.$emit("gameRole");
        return;
      }
      this.$api
        .receive({
          book_token: this.token,
          game_id: 35,
        })
        .then((res) => {
          if (res && res.data.code == 200) {
            Toast("领取成功");
            // 重新获取当前进度
            this.$EventBus.$emit("refreshInit");
          }
        });
    },
    // 分享
    share() {
      if (!this.token) {
        return this.$EventBus.$emit("login");
      }
      if (this.gameCourse.activity.is_share != 1) {
        this.$api
          .share({
            token: this.token,
          })
          .then(() => {
            this.gameCourse.activity.is_share = 1;
          });
      }
      this.$EventBus.$emit("showSharePopup");
    },
    // 刷新亲和度
    refreshAffinity() {
      if (!this.token) {
        return this.$EventBus.$emit("login");
      }
      // 重新获取当前进度
      this.$EventBus.$emit("refreshInit");
    },
    // 活动详情
    detailShow() {
      this.$EventBus.$emit("activityDetail");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./ThreePage.scss";
</style>
