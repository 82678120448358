import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./api/art";
Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$EventBus = new Vue();
import { numberToCurrencyNo } from "@/untils/numberToCurrecy";
Vue.filter("numberToCurrency", numberToCurrencyNo);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
