<template>
  <div class="pc-container">
    <div class="pc-bgc">
      <div class="icon"></div>
      <div class="qrcode-container">
        <div class="qrcode"></div>
        <div class="bot-title"></div>
      </div>
    </div>
    <div class="pc-footer">
      <div class="footer-title">
        <p>
          抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当 适度游戏益脑
          沉迷游戏伤身 合理安排时间 享受健康生活
        </p>
        <p>
          <span
            ><a
              href="https://beian.miit.gov.cn/#/Integrated/index"
              style="margin-left: 0"
              target="_blank"
              >蜀ICP备2023017135号-1</a
            ></span
          >
        </p>
        <p>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002005713"
            ><img
              src="./../../assets/image/pc/police.png"
              alt="图片加载失败"
              style="margin: 4px 0 -3px 0"
            />川公网安备 51019002005713号</a
          >
          Copyright&nbsp;&nbsp;©&nbsp;2021-2023&nbsp;&nbsp;QCPLAY&nbsp;&nbsp;
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { getTimeStamp, buriedLogger } from "@/untils/utils";
export default {
  data() {
    return {
      type: "official",
    };
  },
  created() {
    this.isWeb();
    if (
      this.$router.currentRoute.query.type ||
      localStorage.getItem("fromKey")
    ) {
      this.type =
        this.$router.currentRoute.query.type || localStorage.getItem("fromKey");
      localStorage.setItem("fromKey", this.type);
    }
    const bl = buriedLogger("time-appointment-pc");
    this.record = bl;
    this.record.sum(`时光预约-渠道-${this.type}`);
  },
  methods: {
    isWeb() {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        // User is on a mobile device
        this.$router.push({
          path: `/appointment/enter${getTimeStamp()}`,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./PcHomePage.scss";
</style>
