/*
 * @Autor: lianxiben
 * @Date: 2023-11-17 23:18:40
 * @LastEditors: lianxiben
 * @LastEditTime: 2023-11-20 14:16:45
 */
import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "../views/HomePage/HomePage.vue";
import TransitionPage from "../views/TransitionPage/TransitionPage.vue";
import PcHomePageVue from "@/views/PcHomePage/PcHomePage.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/appointment/enter*",
    name: "home",
    component: HomePage,
  },
  {
    path: "/appointment",
    name: "myhome",
    component: HomePage,
  },
  {
    path: "/appointment/transition",
    name: "transition",
    component: TransitionPage,
  },
  {
    path: "/appointment/transition/enter*",
    name: "transitionsec",
    component: TransitionPage,
  },
  {
    path: "/appointment/pc",
    name: "pc",
    component: PcHomePageVue,
  },
  // {
  //   path: "/",
  //   name: "homepage",
  //   component: HomePage,
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
