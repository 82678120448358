<template>
  <div class="home-page">
    <HeaderContainer class="top-header" />
    <LoginPage />
    <FirstPage />
    <SecPage />
    <ThreePage id="scrollView" />
    <FourPage />
    <FooterPage />
    <div class="box-icon" @click="showBoxPopup()">
      <div class="box-img"></div>
    </div>

    <!-- 规则详情 -->
    <van-popup v-model="show">
      <div class="ruler-bgc"></div>
    </van-popup>

    <!-- 无限宝箱 -->
    <van-popup v-model="boxPopup">
      <div class="box">
        <div class="box-btn" @click="showBoxPay()"></div>
        <!-- <div
          class="box-btn"
          v-if="infoList.activity && infoList.activity.is_pay == 1"
        >
          已购买
        </div> -->
        <!-- <div
          class="bot-tips"
          v-if="infoList.activity && infoList.activity.is_pay != 1"
        >
          价值: 128元
        </div> -->
        <!-- <div
          class="bot-tips bot-tips-sec"
          v-if="infoList.activity && infoList.activity.is_pay == 1"
        >
          游戏上线后点击一键领取获得
        </div> -->
      </div>
    </van-popup>

    <!-- 分享 -->
    <van-popup v-model="sharePopup">
      <div class="share-bgc">
        <div class="share-url">
          {{ link }}
        </div>
        <div
          class="share-btn"
          id="clipboard-btn"
          :data-clipboard-text="url"
          @click="onCopy"
        ></div>
      </div>
    </van-popup>

    <!-- 一键加群二维码 -->
    <van-popup v-model="joinGroupPopup">
      <div class="qrcode-bgc">
        <img :src="groupQrcode" alt="" />
      </div>
    </van-popup>
    <!-- 微信 -->
    <van-popup v-model="wechatGzh">
      <div class="gzh-bgc">
        <img src="./../../assets/image/popup/gzh.jpg" alt="" />
      </div>
    </van-popup>
    <!-- 付款方式 -->
    <van-popup v-model="payListPopup">
      <div class="pay-popup">
        <div class="title">付款方式</div>
        <van-radio-group v-model="radio">
          <van-radio
            name="wechat"
            checked-color="#CD7957"
            label-position="left"
            icon-size="25"
            v-if="isWechatUa"
          >
            <div class="radio-left">
              <img src="./../../assets/image/popup/wechat.png" alt="" />
              <div>微信</div>
            </div>
          </van-radio>
          <van-radio
            name="alipay"
            checked-color="#CD7957"
            label-position="left"
            icon-size="25"
            v-if="!isWechatUa"
          >
            <div class="radio-left">
              <img src="./../../assets/image/popup/zfb.png" alt="" />
              <div>支付宝</div>
            </div>
          </van-radio>
        </van-radio-group>
        <div class="bot-btn">
          <div class="btn" @click="payBtn()">立即支付</div>
        </div>
      </div>
    </van-popup>

    <!-- 新手引导 -->
    <van-popup v-model="guidePopup">
      <div class="guide1" v-show="guideType == 1" @click="guideType = 2"></div>
      <div class="guide2" v-show="guideType == 2" @click="guideType = 3"></div>
      <div class="guide3" v-show="guideType == 3" @click="overGuide()"></div>
    </van-popup>

    <!-- 首次预约成功 -->
    <van-popup v-model="firstPopup" :close-on-click-overlay="false">
      <div class="first-popup">
        <div class="btn" @click="closePopup()"></div>
      </div>
    </van-popup>

    <!-- 引导到外部的弹窗 -->
    <van-popup v-model="guideOutPopup">
      <div class="guide-out-popup" @click="guideOutPopup = false">
        <img src="./../../assets/image/popup/wechat-code.png" alt="" />
      </div>
    </van-popup>
  </div>
</template>
<script>
import LoginPage from "@/components/LoginPage/LoginPage.vue";
import FirstPage from "@/components/FirstPage/FirstPage.vue";
import SecPage from "@/components/SecPage/SecPage.vue";
import ThreePage from "@/components/ThreePage/ThreePage.vue";
import FourPage from "@/components/FourPage/FourPage.vue";
import HeaderContainer from "@/components/HeaderContainer/HeaderContainer.vue";
import FooterPage from "@/components/FooterPage/FooterPage.vue";
import wx from "weixin-js-sdk";
import { getTimeStamp, buriedLogger } from "@/untils/utils";
import { Toast, RadioGroup, Radio } from "vant";
import { mapActions } from "vuex";
import Clipboard from "clipboard";
import Vue from "vue";
Vue.use(Radio);
Vue.use(RadioGroup);
export default {
  components: {
    LoginPage,
    FirstPage,
    SecPage,
    ThreePage,
    FourPage,
    HeaderContainer,
    FooterPage,
  },
  data() {
    return {
      guideOutPopup: false, //微信外部引导弹窗
      wechatGzh: false, //微信公众号引导弹窗
      joinGroupPopup: false, //一键加群二维码弹窗
      firstPopup: false, //首次预约成功弹窗
      guideType: 1, //引导弹窗序号
      guidePopup: false, //新手引导弹窗
      isWechatUa: false,
      token: "",
      infoList: {}, //初始化信息
      radio: "wechat", //支付类型
      canPay: true, //支付防重复点击
      domain: "", //裁剪二级域名做判断
      show: false, //规则弹窗
      boxPopup: false, //宝箱弹窗
      sharePopup: false, //分享弹窗
      url: "", //分享复制的内容
      payListPopup: false, //支付选择弹窗
      record: "", //埋点对象
      link: "",
      groupQrcode: "", //一键加群二维码
      payTime: 0,
      type: "official", //渠道类型
    };
  },
  mounted() {
    const bl = buriedLogger("time-appointment-h5");
    this.record = bl;
    if (
      this.$router.currentRoute.query.type ||
      localStorage.getItem("fromKey")
    ) {
      this.type =
        this.$router.currentRoute.query.type || localStorage.getItem("fromKey");
      localStorage.setItem("fromKey", this.type);
    }
    this.record.sum(`时光预约-渠道-${this.type}`);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (!isMobile) {
      // User is on a mobile device
      this.$router.push({
        path: `/appointment/pc`,
      });
      return;
    }
    const masterUrl = window.location.href;
    this.domain = masterUrl.split("/")[2];
    this.token = localStorage.getItem("token");
    if (this.token) {
      this.getList();
    }
    this.getBookNum();
    // evenbus刷新init数据
    this.$EventBus.$on("refreshInit", (e) => {
      this.token = localStorage.getItem("token");
      this.getList(e);
    });
    // 滚动监听
    this.$EventBus.$on("scrollClick", () => {
      let element = document.getElementById("scrollView");
      element.scrollIntoView();
      element.scrollIntoView(false);
      element.scrollIntoView({ block: "end" });
    });
    // 领奖按钮点击记录
    this.$EventBus.$on("rewardClick", () => {
      this.record.sum("时光预约-一键领奖");
    });
    // 活动详情
    this.$EventBus.$on("activityDetail", () => {
      this.record.sum("时光预约-详情按钮点击");
      this.show = true;
    });
    // 社交任务点击记录
    this.$EventBus.$on("taskClickRecord", (e) => {
      // 判断是否为微信浏览器
      switch (e) {
        case 1:
          this.record.sum("时光预约-TapTap点击");
          window.location.href = "https://www.taptap.cn/app/387611";
          break;
        case 2:
          this.record.sum("时光预约-公众号关注引导");
          this.wechatGzh = true;
          break;
        case 3:
          this.record.sum("时光预约-一键加群");
          this.guideOutPopup = true;
          break;
        default:
      }
    });
    // 注销
    this.$EventBus.$on("logout", () => {
      this.token = "";
    });
    // 首页弹窗QQ群二维码
    this.$EventBus.$on("showGroup", () => {
      // 判断是否为微信浏览器
      const ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.guideOutPopup = true;
        return;
      }
      this.groupPopup();
    });
    // 获取路由参数tradeNo查询支付情况
    if (this.$route.query.tradeNo) {
      this.bingdingWating();
    }
    // 首次新手引导
    this.$EventBus.$on("showGuidePopup", () => {
      this.guidePopup = true;
    });
    // 判断是否为微信浏览器
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.isWechatUa = true;
      this.isSetShare();
    } else {
      this.radio = "alipay";
    }
    // 调出分享弹窗
    this.$EventBus.$on("showSharePopup", () => {
      this.sharePopup = true;
      const masterUrl = window.location.href;
      const domain = masterUrl.split("/")[2];
      this.link = `https://${domain}/appointment/enter${getTimeStamp()}`;
      this.url = `青瓷游戏新作《时光旅行社》开业定档12月22日，参与活动领橙卡、星石等道具，更有机会获得开业限定形象，点击链接https://${domain}/appointment/enter${getTimeStamp()}?type=${
        this.type
      }`;
    });
  },
  methods: {
    // 初始化数据
    getList(e) {
      this.$api
        .init({
          game_id: 35,
          book_token: this.token,
          is_role: e,
        })
        .then((res) => {
          if (res && res.data.code == 200) {
            this.infoList = res.data.data;
            this.$EventBus.$emit("initSuccess", this.infoList);
            // 判断是否为微信浏览器
            const ua = window.navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
              this.wechatPay();
              return;
            }
            // 判断是否为预约成功切首次登录的
            if (this.infoList.activity.is_novice == 0) {
              this.firstPopup = true;
              this.record.sum(`时光预约-首次预约渠道-${this.type}`);
            }
          } else {
            //清空登录信息
            this.$EventBus.$emit("loginOutInit");
          }
        });
    },
    // 获取预约人数
    getBookNum() {
      this.$api.getBookNum().then((res) => {
        if (res && res.data.code == 200) {
          this.$EventBus.$emit("getBookNum", res.data.data.book_num);
        }
      });
    },
    // 获取微信支付配置
    wechatPay() {
      this.$api
        .wechatAuthorization({
          code: this.infoList.userinfo.id,
        })
        .then((res) => {
          console.log(res.data.data.open_id);
          if (res.data.data.open_id) {
            localStorage.setItem("openId", res.data.data.open_id);
            // 判断是否为预约成功切首次登录的
            if (this.infoList.activity.is_novice == 0) {
              this.firstPopup = true;
              this.record.sum(`时光预约-首次预约渠道-${this.type}`);
            }
            return;
          }
          // const masterUrl = window.location.href;
          // const domain = masterUrl.split("/")[2];
          const base = {
            url: "//api.qingcigame.com",
          };
          window.location.href = `${
            base.url
          }/wechat/oauth/login/?back=${encodeURIComponent(
            window.location.href
          )}&code_id=${this.infoList.userinfo.id}`;
        });
    },
    // 完成新手引导
    overGuide() {
      localStorage.setItem("guidePopup", 1);
      this.guidePopup = false;
    },
    // 无限宝盒弹窗
    showBoxPopup() {
      this.record.sum("时光预约-点击无限宝盒");
      if (!this.token) {
        return this.$EventBus.$emit("login");
      }
      // if (this.infoList.activity.is_pay == 1) {
      //   return Toast("您已购买宝盒，请点击一键领奖领取奖励");
      // }
      this.boxPopup = true;
    },
    // 立即支付
    payBtn() {
      if (!this.canPay) {
        return Toast("支付中，请勿重复点击");
      }
      this.record.sum("时光预约-无限宝盒购买点击");
      this.canPay = false;
      let payMethod = this.radio == "alipay" ? "wap" : "mp";
      const domain1 = this.domain;
      this.$api
        .payOne({
          game_id: 35,
          pay_method: payMethod,
          pay_type: this.radio,
          open_id: localStorage.getItem("openId"),
          book_token: localStorage.getItem("token"),
          sdk_access_token: localStorage.getItem("sdkToken"),
          return_url: `https://${this.domain}/appointment/`,
        })
        .then((res) => {
          if (res && res.data.code == 200) {
            if (this.radio == "alipay") {
              document.getElementById("aliPayBtn").innerHTML =
                res.data.data.data;
              document.forms["alipaysubmit"].submit();
              setTimeout(() => {
                this.canPay = true;
              }, 3000);
            } else {
              var order = res.data.data.order;
              setTimeout(() => {
                this.canPay = true;
              }, 3000);
              wx.chooseWXPay({
                timestamp: res.data.data.data.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
                nonceStr: res.data.data.data.nonceStr, // 支付签名随机串，不长于 32 位
                package: res.data.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                signType: res.data.data.data.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                paySign: res.data.data.data.paySign, // 支付签名
                success() {
                  window.location.href = `https://${domain1}/appointment/enter${getTimeStamp()}?tradeNo=${order}`;
                },
                cancel() {
                  Toast("取消支付");
                },
                fail() {
                  Toast("支付异常");
                },
              });
            }
          } else {
            setTimeout(() => {
              this.canPay = true;
            }, 2000);
          }
          // else if (res.data.code == 401) {
          //   Toast(res.message);
          //   setTimeout(() => {
          //     this.canPay = true;
          //   }, 2000);
          // } else {
          //   Toast(res.message);
          //   setTimeout(() => {
          //     this.canPay = true;
          //   }, 2000);
          // }
        });
    },

    //订单查询
    bingdingWating() {
      this.$api
        .bingdingWating({
          trade_no: this.$route.query.tradeNo,
        })
        .then((res) => {
          if (this.payTime < 10) {
            this.payTime++;
            setTimeout(() => {
              this.bingdingWating();
            }, 1000);
            return;
          } else if (res.data.code == 200) {
            Toast("支付成功");
            this.getList();
            setTimeout(() => {
              // 重定向到本页路由去除传参
              this.$router.replace({
                path: `/appointment/enter${getTimeStamp()}`,
              });
            }, 1000);
            return;
          } else if (this.payTime >= 10) {
            Toast("支付失败");
          }
        });
    },
    isSetShare() {
      // const masterUrl = window.location.href;
      // const domain = masterUrl.split("/")[2];
      this.setShare({
        link: window.location.href,
        title: "时光旅行社开业邀请函",
        imgUrl:
          "https://download4.qcplay.com/qcgame-api/SDD4TLA1HmBvKFV8BwHE9sVSjjDZn63zXZx8dHAD.png",
        desc: "12月22日，无限流，启动！",
      });
    },
    // 购买宝盒
    showBoxPay() {
      if (!this.token) {
        return this.$EventBus.$emit("login");
      }
      if (this.infoList.activity.is_pay == 1) {
        return Toast("您已解锁礼包，无需重复购买");
      }
      this.payListPopup = true;
    },
    // 首次预约完成
    closePopup() {
      this.$api
        .firstAppointment({
          token: this.token,
        })
        .then((res) => {
          if (res && res.data.code == 200) {
            this.firstPopup = false;
          }
        });
    },
    // 一键加群弹窗
    groupPopup() {
      this.$api
        .group({
          game_id: 35,
        })
        .then((res) => {
          console.log(res);
          this.groupQrcode = res.data.data.channel_url.qq_image;
          this.joinGroupPopup = true;
        });
    },
    // 复制链接
    onCopy() {
      this.record.sum("时光预约-立即分享按钮点击");
      const clipboard = new Clipboard("#clipboard-btn");
      clipboard.on("success", function (e) {
        Toast("复制成功");
        e.clearSelection();
        // // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        Toast("复制失败");
        // 释放内存
        clipboard.destroy();
      });
      // });
    },
    ...mapActions(["setShare"]),
  },
};
</script>
<style lang="scss" scoped>
@import "./HomePage.scss";
</style>
