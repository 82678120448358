import { render, staticRenderFns } from "./TransitionPage.vue?vue&type=template&id=26f88c3e"
import script from "./TransitionPage.vue?vue&type=script&lang=js"
export * from "./TransitionPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports