<template>
  <div></div>
</template>
<script>
import { getTimeStamp } from "@/untils/utils";
export default {
  created() {
    if (this.$router.currentRoute.query.type) {
      localStorage.setItem("fromKey", this.$router.currentRoute.query.type);
    } else {
      localStorage.setItem("fromKey", "official");
    }
    this.isWeb();
  },
  methods: {
    isWeb() {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        // User is on a mobile device
        this.$router.push({
          path: `/appointment/enter${getTimeStamp()}`,
        });
      } else {
        this.$router.push({
          name: "pc",
        });
      }
    },
  },
};
</script>
