/*
 * @Autor: lianxiben
 * @Date: 2021-05-25 14:16:10
 * @LastEditors: lianxiben
 * @LastEditTime: 2023-06-10 14:52:57
 */
import base from "./base"; // 导入接口域名列表
import instance from "./../untils/http";
// import instance from "instance";
import qs from "qs"; //
const art = {
  //登录成功后获取区服信息
  getGameId(params) {
    return instance.get(
      `${base.url}/community/game/info?account=${params.account}&&app_id=58`,
      {
        withCredentials: true,
      }
    );
  },
  // 获取区服信息
  getGameIdNew(params) {
    return instance.post(
      `${base.url}/sword/book/get/role`,
      qs.stringify(params),
      {
        withCredentials: true,
      }
    );
  },
  //发送短信验证码
  sendMsg(params) {
    return instance.post(`${base.url}/login/sms/send`, qs.stringify(params), {
      withCredentials: true,
    });
  },
  //手机登录
  loginByPhone(params) {
    return instance.post(`${base.url}/login/free/sms`, qs.stringify(params), {
      withCredentials: true,
    });
  },
  //初始化
  init(params) {
    return instance.post(`${base.url}/travel/book/init`, qs.stringify(params), {
      withCredentials: true,
    });
  },
  // 升级建筑物
  upgrade(params) {
    return instance.post(
      `${base.url}/travel/book/build/make`,
      qs.stringify(params),
      {
        withCredentials: true,
      }
    );
  },
  // 完成任务
  finish(params) {
    return instance.post(`${base.url}/travel/book/task`, qs.stringify(params), {
      withCredentials: true,
    });
  },
  // 一键收取亲密度
  collect(params) {
    return instance.post(
      `${base.url}/travel/book/collect`,
      qs.stringify(params),
      {
        withCredentials: true,
      }
    );
  },
  // 支付订单查询
  query(params) {
    return instance.get(
      `${base.url}/travel/book/pay/query?tradeNo=${params.tradeNo}`,
      {
        withCredentials: true,
      }
    );
  },
  // 一键领取奖励
  receive(params) {
    return instance.post(
      `${base.url}/travel/book/receive`,
      qs.stringify(params),
      {
        withCredentials: true,
      }
    );
  },
  // 预约人数
  getBookNum() {
    return instance.get(`${base.url}/travel/book/num`, {
      withCredentials: true,
    });
  },
  //角色绑定
  bingding(params) {
    return instance.post(`${base.url}/game/binds/role`, qs.stringify(params), {
      withCredentials: true,
    });
  },
  //微信授权
  wechatAuthorization(params) {
    return instance.get(
      `${base.url}/wechat/obtain/openid?code_id=${params.code}`,
      {
        withCredentials: true,
      }
    );
  },
  // jssdk
  settingShare(params) {
    return instance.post(`${base.url}/wechat/jssdk`, qs.stringify(params), {
      withCredentials: true,
    });
  },
  // 支付
  payOne(params) {
    return instance.post(`${base.url}/travel/book/buy`, qs.stringify(params), {
      withCredentials: true,
    });
  },
  //订单支付查询
  bingdingWating(params) {
    return instance.get(`${base.url}/travel/book/show/${params.trade_no}`, {
      withCredentials: true,
    });
  },
  // 每日分享
  share(params) {
    return instance.get(
      `${base.url}/travel/book/share?book_token=${params.token}&game_id=35`,
      {
        withCredentials: true,
      }
    );
  },
  // 首次预约完成
  firstAppointment(params) {
    return instance.get(
      `${base.url}/travel/book/novice?book_token=${params.token}&game_id=35`,
      {
        withCredentials: true,
      }
    );
  },
  // 一键加群弹窗
  group(params) {
    return instance.post(
      `${base.url}/channel/join/group`,
      qs.stringify(params),
      {
        withCredentials: true,
      }
    );
  },
  // 下载
  downloadUrl() {
    return instance.get(`${base.url}/get_url?game_id=35`, {
      withCredentials: true,
    });
  },
};

export default art;
